import merchantCategoryCodeRoutes from '@/router/settings/account-merchant-category-code';
import { abasalaryMappingsRoutes } from '@/router/settings/accounting/abasalary-mappings';
import { apiTokensRoutes } from '@/router/settings/api-tokens';
import { emailRoutes } from '@/router/settings/email';
import { settingsTimeTrackingRoutes } from '@/router/settings/time-tracking';

const DocumentDesigner = () => import('@/pages/settings/document-designer/DocumentDesigner.vue');
const PassThrough = () => import('@/views/PassThrough.vue');
const PassThroughKeyed = () => import('@/views/PassThroughKeyed.vue');
const Registration = () => import('@/pages/settings/Registration.vue');
const RegistrationAccountPlanFlexible = () => import('@/pages/settings/RegistrationAccountPlanFlexible.vue');
const Settings = () => import('@/pages/settings/Settings.vue');
const SettingsAccountPlan = () => import('@/pages/settings/accounting/account-plan/SettingsAccountPlan.vue');
const SettingsAccountPlanBookingAccount = () => import('@/pages/settings/accounting/account-plan/SettingsAccountPlanBookingAccount.vue');
const SettingsAccountPlanClassification = () => import('@/pages/settings/accounting/account-plan/SettingsAccountPlanClassification.vue');
const SettingsAccountProposalItem = () => import('@/pages/settings/accounting/account-proposals/SettingsAccountProposalItem.vue');
const SettingsAccountProposals = () => import('@/pages/settings/accounting/account-proposals/SettingsAccountProposals.vue');
const SettingsAgreementsConfirmation = () => import('@/pages/settings/SettingsAgreementsConfirmation.vue');
const SettingsConfirm = () => import('@/pages/settings/SettingsConfirm.vue');
const SettingsDocumentDesign = () => import('@/pages/settings/customer/SettingsDocumentDesign.vue');
const SettingsKeyFigure = () => import('@/pages/settings/accounting/SettingsKeyFigure.vue');
const SettingsList = () => import('@/pages/settings/SettingsList.vue');
const SettingsModuleAbacus = () => import('@/pages/settings/company/SettingsModuleAbacus.vue');
const SettingsModuleAbaNet = () => import('@/pages/settings/company/SettingsModuleAbaNet.vue');
const SettingsModuleDeepBox = () => import('@/pages/settings/company/SettingsModuleDeepBox.vue');
const SettingsModuleDeepCloud = () => import('@/pages/settings/company/SettingsModuleDeepCloud.vue');
const SettingsModules = () => import('@/pages/settings/company/SettingsModules.vue');
const SettingsPaypal = () => import('@/pages/settings/customer/SettingsPaypal.vue');
const SettingsReceipt = () => import('@/pages/settings/SettingsReceipt.vue');
const SettingsStripe = () => import('@/pages/settings/customer/SettingsStripe.vue');
const SettingsTax = () => import('@/pages/settings/accounting/tax/SettingsTax.vue');
const SettingsTaxRate = () => import('@/pages/settings/accounting/tax/SettingsTaxRate.vue');
const SettingsTaxTimeline = () => import('@/pages/settings/accounting/tax/SettingsTaxTimeline.vue');
const SettingsUnitCreate = () => import('@/pages/settings/baseData/settings-unit/SettingsUnitCreate.vue');
const SettingsUnitView = () => import('@/pages/settings/baseData/settings-unit/SettingsUnitView.vue');
export const agbRouteName = 'SettingsAgreementsConfirmation';
export const confirmRouteName = 'SettingsConfirm';
export const flexibleAccountRegistrationRouteName = 'RegistrationAccountPlanFlexible';
export const registrationRouteName = 'SettingsRegistration';

export default [
  {
    path: '/settings',
    component: Settings,
    children: [
      ...apiTokensRoutes,
      ...abasalaryMappingsRoutes,
      ...emailRoutes,
      {
        path: '',
        name: 'settings',
        component: PassThrough,
        redirect: { name: 'SettingsList' },
        children: [
          {
            path: 'confirm',
            name: confirmRouteName,
            component: SettingsConfirm,
          },
          {
            path: 'agreements-confirm',
            name: agbRouteName,
            component: SettingsAgreementsConfirmation,
          },
          {
            path: 'registration',
            name: registrationRouteName,
            component: Registration,
          },
          {
            path: 'registration-account-plan-flexible',
            name: flexibleAccountRegistrationRouteName,
            component: RegistrationAccountPlanFlexible,
          },
          {
            path: 'list',
            name: 'SettingsList',
            component: SettingsList,
          },
          {
            path: 'company',
            name: 'SettingsCompanyDetail',
            redirect: '/v3/settings/company/company-data',
          },
          {
            path: 'user_management',
            name: 'SettingsPermissionManagement',
            redirect: '/v3/settings/company/user-management',
          },
          {
            path: 'user_management/user/:id?',
            name: 'SettingsPermissionUser',
            redirect: '/v3/settings/company/user-management/user/:id',
          },
          {
            path: 'user_management/group/:id',
            name: 'SettingsPermissionGroup',
            redirect: '/v3/settings/company/user-management/user-group/:id',
          },
          {
            path: 'localization',
            name: 'SettingsLocalization',
            redirect: '/v3/settings/company/localisation',
          },
          {
            path: 'modules',
            name: 'SettingsModules',
            component: SettingsModules,
          },
          {
            path: 'modules/abacus',
            name: 'SettingsModuleAbacus',
            component: SettingsModuleAbacus,
          },
          {
            path: 'modules/abanet',
            name: 'SettingsModuleAbaNet',
            component: SettingsModuleAbaNet,
          },
          {
            path: 'modules/deepbox',
            name: 'SettingsModuleDeepBox',
            component: SettingsModuleDeepBox,
          },
          {
            path: 'modules/deepcloud',
            name: 'SettingsModuleDeepCloud',
            component: SettingsModuleDeepCloud,
          },
          {
            path: 'address',
            name: 'SettingsAddress',
            redirect: '/v3/settings/base-data/address',
          },
          {
            path: 'product',
            name: 'SettingsProduct',
            redirect: '/v3/settings/base-data/product',
          },
          {
            path: 'unit',
            name: 'SettingsUnit',
            component: PassThroughKeyed,
            redirect: { name: 'SettingsUnitView' },
            children: [
              {
                path: 'view',
                name: 'SettingsUnitView',
                component: SettingsUnitView,
                // redirect: '/v3/settings/base-data/units',
              },
              {
                path: 'create',
                name: 'SettingsUnitCreate',
                component: SettingsUnitCreate,
                // redirect: '/v3/settings/base-data/units/unit/create',
              },
              {
                path: 'edit/:unitUuid',
                props: true,
                name: 'SettingsUnitEdit',
                component: SettingsUnitCreate,
                // redirect: '/v3/settings/base-data/units/unit/:unitUuid',
              },
            ],
          },
          {
            path: 'receipt',
            name: 'SettingsReceipt',
            component: SettingsReceipt,
          },
          {
            path: 'supplier-credit-note',
            name: 'SettingsSupplierCreditNote',
            redirect: '/v3/settings/suppliers/supplier-credit-note',
          },
          {
            path: 'layout',
            name: 'SettingsDocumentDesign',
            component: SettingsDocumentDesign,
          },
          {
            path: 'document-designer',
            name: 'document-designer',
            component: DocumentDesigner,
          },

          {
            path: 'import-export',
            name: 'SettingsImportExport',
            redirect: '/v3/settings/base-data/import-export',
          },

          {
            path: 'invoice',
            name: 'SettingsInvoice',
            redirect: '/v3/settings/customer/invoices',
          },
          {
            path: 'dunning/:lvl?',
            name: 'SettingsDunning',
            redirect: '/v3/settings/customer/invoices/dunning/:lvl?',
          },
          {
            path: 'gateway/paypal/:id?',
            name: 'SettingsPaypal',
            component: SettingsPaypal,
          },
          {
            path: 'gateway/stripe/:id?',
            name: 'SettingsStripe',
            component: SettingsStripe,
          },
          {
            path: 'quote',
            name: 'SettingsQuote',
            redirect: '/v3/settings/customer/quotes',
          },
          {
            path: 'contract-note',
            name: 'SettingsContractNote',
            redirect: '/v3/settings/customer/contract-notes',
          },
          {
            path: 'delivery-note',
            name: 'SettingsDeliveryNote',
            redirect: '/v3/settings/customer/delivery-notes',
          },
          {
            path: 'credit-note',
            name: 'SettingsCreditNote',
            redirect: '/v3/settings/customer/credit-notes',
          },
          {
            path: 'mails',
            name: 'SettingsMails',
            redirect: '/v3/settings/customer/email',
          },
          {
            path: 'mail-designer',
            name: 'SettingsMailDesigner',
            redirect: '/v3/settings/customer/email/designer',
          },
          {
            path: 'mail/:id',
            name: 'SettingsMail',
            redirect: '/v3/settings/customer/email/templates/:id',
          },
          {
            path: 'currencies',
            name: 'SettingsCurrencies',
            redirect: '/v3/settings/accounting/currencies',
          },
          {
            path: 'currency/:id?',
            name: 'SettingsCurrency',
            redirect: '/v3/settings/accounting/currencies/currency/create',
          },
          {
            path: 'booking-years',
            name: 'SettingsJournalYears',
            redirect: '/v3/settings/accounting/booking-years',
          },
          {
            path: 'accounting',
            component: PassThroughKeyed,
            children: [
              ...merchantCategoryCodeRoutes,
              {
                path: 'account-plan',
                component: PassThroughKeyed,
                redirect: { name: 'settings-account-plan' },
                children: [
                  {
                    path: '',
                    name: 'settings-account-plan',
                    component: SettingsAccountPlan,
                  },
                  {
                    path: 'classification/:id?',
                    name: 'settings-account-plan-classification',
                    component: SettingsAccountPlanClassification,
                  },
                  {
                    path: 'booking-account/:id?',
                    name: 'settings-account-plan-booking-account',
                    component: SettingsAccountPlanBookingAccount,
                  },
                ],
              },
              {
                path: 'account-proposals',
                component: PassThroughKeyed,
                redirect: { name: 'settings-account-proposals' },
                children: [
                  {
                    path: '',
                    name: 'settings-account-proposals',
                    component: SettingsAccountProposals,
                  },
                  {
                    path: 'account-proposal/:id?',
                    name: 'settings-account-proposal-item',
                    component: SettingsAccountProposalItem,
                  },
                ],
              },
            ],
          },
          {
            path: 'tax',
            component: PassThroughKeyed,
            redirect: { name: 'SettingsTax' },
            children: [
              {
                path: '',
                name: 'SettingsTax',
                component: SettingsTax,
              },
              {
                path: 'timeline/:uuid?',
                name: 'SettingsTaxTimeline',
                component: SettingsTaxTimeline,
                props: true,
              },
              {
                path: 'rate/:id?',
                name: 'SettingsTaxRate',
                component: SettingsTaxRate,
              },
            ],
          },
          {
            path: 'key-figures',
            name: 'SettingsKeyFigures',
            redirect: '/v3/settings/accounting/key-figures',
          },
          {
            path: 'key-figures/year/:id?',
            name: 'SettingsKeyFigure',
            component: SettingsKeyFigure,
          },
          {
            path: 'general/display',
            name: 'settings-general-display',
            redirect: '/v3/settings/general/display-options',
          },

          ...settingsTimeTrackingRoutes,
        ],
      },
    ],
  },
];
